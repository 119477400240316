import axios from "../axios-config";

/**
 * 题库类别，资料类别，培训类别下载
 */
//  export function questionType(params: any) {
//     return axios.get("/api/auth/userInfo/page", {responseType: 'blob',params:{
//       ...params
//     }});
//   }
/**
 * 试题管理
 */
 export function questionManagement(params: any) {
    return axios.get("/api/back/learn/QuestionController/download", {responseType: 'blob',params:{
      ...params
    }});
  }
  //选中下载
  export function questionManagementDownloadSelectData(params: any) {
    return axios.get("/api/back/learn/QuestionController/downloadSelectData", {responseType: 'blob',params:{
      ...params
    }});
  }
/**
 * 资料管理
 */
 export function documentation(params: any) {
  return axios.get("/api/back/learn/MaterialController/download", {responseType: 'blob',params:{
    ...params
  }});
}
//选中下载
export function documentationDownloadSelectData(params: any) {
  return axios.get("/api/back/learn/MaterialController/downloadSelectData", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 培训计划
 */
 export function TrainingPlanController(params: any) {
  return axios.get("/api/back/learn/TrainingPlanController/download", {responseType: 'blob',params:{
    ...params
  }});
}
//选中下载
export function TrainingPlanControllerDownloadSelectData(params: any) {
  return axios.get("/api/back/learn/TrainingPlanController/downloadSelectData", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 考卷管理
 */
 export function ExaminationPaperController(params: any) {
  return axios.get("/api/back/learn/ExaminationPaperController/download", {responseType: 'blob',params:{
    ...params
  }});
}
//选中下载
export function ExaminationPaperControllerDownloadSelectData(params: any) {
  return axios.get("/api/back/learn/ExaminationPaperController/downloadSelectData", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 考试计划
 */
 export function ExaminationPlanController(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanController/download", {responseType: 'blob',params:{
    ...params
  }});
}
//选中下载
export function ExaminationPlanControllerDownloadSelectData(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanController/downloadSelectData", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 考试成绩管理
 */
 export function ExaminationPlanToUserController(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanToUserController/download", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 培训访客
 */
 export function VisitorControllerDownload(params: any) {
  return axios.get("/api/back/learn/VisitorController/download", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 题库练习统计
 */
 export function statisticsDownload(params: any) {
  return axios.get("/api/back/learn/QuestionRecordController/statisticsDownload", {responseType: 'blob',params:{
    ...params
  }});
}
/**
 * 考试成绩统计
 */
 export function ExaminationPlanToUserControllerStatisticsDownload(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanToUserController/statisticsDownload", {responseType: 'blob',params:{
    ...params
  }});
}
//学习时长
export function DurationStatisticsControllerDownload(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToAchievementController/durationStatisticsdownload", {responseType: 'blob',params:{
    ...params
  }});
}
//线上培训（培训详情导出）
export function TrainingPlanToUserControllerDownload(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToUserController/download", {responseType: 'blob',params:{
    ...params
  }});
}
//学习时长统计（详情导出）
export function durationStatisticsInfodownloadDownload(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToAchievementController/durationStatisticsInfodownload", {responseType: 'blob',params:{
    ...params
  }});
}
