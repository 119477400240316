<template>
  <a-upload name="file" :multiple="true" :headers="headers" :action="api + '/api/back/upload/uploadFile'"
    :before-upload="beforeUpload" :file-list="fileList" @change="handleChange" :disabled="disabled">
    <a-button v-if="fileList.length < 1">
      上传附件
    </a-button>
  </a-upload>
</template>
<script>
import { message } from "ant-design-vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default {
  props: {
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      disabled: false,
      api: process.env.VUE_APP_API_BASE_URL,
      fileList: [],
      headers: {
        Authorization: cookies.get("TOKEN_ACCOUNT") ? 'Bearer ' + cookies.get("TOKEN_ACCOUNT") : null,
      },
    };
  },
  methods: {
    beforeUpload: (file) => {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'video/mp4';
        if (!isJpgOrPng) {
          message.error('请上传PDF格式或者mp4格式');
          return reject(false);
        }
        const isLt2M = file.size / 1024 / 1024 < 500;
        if (!isLt2M) {
          message.error('上传的文件大小超过500M');
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleChange({ file, fileList }) {
      this.fileList = fileList;
      this.$emit('handleChange', file)
    },
    init() {
      this.fileList = []
      if (this.FormData.path) {
        this.fileList.push({ url: this.FormData.path, name: this.FormData.name, })
      }
    }
  },
};
</script>
  