<template>
  <div class="clearfix">
    <a-upload :action="api + '/api/back/upload/uploadFile'" list-type="picture-card" :headers="headers" :file-list="fileList"
      @preview="handlePreview" :before-upload="beforeUpload" @change="handleChange">
      <div v-if="fileList.length < 1">
        <div class="ant-upload-text">
          <myIcon name="add-one" /><br>
          添加
        </div>
      </div>
    </a-upload>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import myIcon from "@/components/icon/index";
import { message } from "ant-design-vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default {
  props: {
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      api: process.env.VUE_APP_API_BASE_URL,
      previewVisible: false,
      previewImage: '',
      headers: {
        Authorization: cookies.get("TOKEN_ACCOUNT") ? 'Bearer ' + cookies.get("TOKEN_ACCOUNT") : null,
      },
      fileList: [
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
      ],
    };
  },
  components: {
    myIcon,
  },
  methods: {
    beforeUpload: (file) => {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('请上传JPG、PNG格式');
          return reject(false);
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          message.error('上传的文件大小不超过1M');
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      this.fileList = fileList;
      this.$emit('handleChange', file)
    },
    init() {
      this.fileList = []
      if (this.FormData.img) {
        this.fileList.push({ url: this.FormData.img, name: this.FormData.name, })
      }
    }
  },
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
