
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  getCurrentInstance,
  ComponentInternalInstance,
  createVNode,
} from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import { documentation,documentationDownloadSelectData } from '@/api/downloadApi'
import {
  getPage,
  del,
  updateStatus,
  findByTree,
} from "@/api/meaterial/documentation";
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";


const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "资料封面",
    dataIndex: "img",
    slots: { customRender: "cover" },
  },
  {
    title: "资料名称 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "资料类别",
    dataIndex: "stringCategory",
  },
  {
    title: "资料类别",
    dataIndex: "type",
    customRender: (row: TransformCellTextProps) =>
      row.text === 1 ? "文档类" : "视频类",
  },
  {
    title: "资料状态",
    dataIndex: "status",
    customRender: (row: TransformCellTextProps) =>
      row.text === 0 ? "未发布" : "已发布",
  },
  {
    title: "创建人",
    dataIndex: "createdBy",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    sorter: true,
  },
  {
    title: "更新人",
    dataIndex: "updatedBy",
  },
  {
    title: "更新时间",
    dataIndex: "updatedTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 300,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    const formSearch = ref();
    const popupWindow = ref();
    const table = ref();

    const data = reactive({
      purchaseColumns,
      categoryIds: [],
      selectedRowKeys: [],
      extraModel: {},
      formEdit: false,
      formBtn: {
        colProps: { span: 6 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "资料名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "title", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        //试题类别：
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "资料类别",
          },
          comConfig: {
            tag: "input",
            key: "categoryIdSlots",
            props: {
              placeholder: "请选择",
            },
          },
        },
        // 更新时间
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "创建时间",
          },
          comConfig: {
            tag: "input",
            key: "testSlots",
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const params_api = ref()
    const updatedTime = ref([]);
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'startUpdateTime': updatedTime.value[0],
          'endUpdateTime': updatedTime.value[1],
          categoryId: data.categoryIds[data.categoryIds.length - 1],
          sort:params.sort
        }
        getPage({ ...params_api.value}).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      documentation({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        documentationDownloadSelectData({ ids:data.selectedRowKeys }).then(res => {
        blob(res)
      })
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };

    const handleAdd = () => {
      data.formEdit = false;
      popupWindow.value.showModal();
    };

    const handleUpdate = (row: any) => {
      data.formEdit = true;
      popupWindow.value.showModal({ ...row });
    };

    const handleDelete = async (ids: string[]) => {
      del(ids.join(",")).then(() => {
        (proxy as any).$message.success("资料删除成功");
        table.value.refresh();
      });
    };

    const handleDeleteAll = () => {
      (proxy as any).$confirm({
        title: "删除确认",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "确认删除选中资料吗？",
        okType: " ",
        onOk: () => {
          return new Promise((resolve) => {
            handleDelete(data.selectedRowKeys).then(() => {
              data.selectedRowKeys = []
            })
              .finally(() => {
                resolve(true)
              });
          });
        },
      });
    };

    const handleUpdateStatus = (status: string) => {
      const statusName = status === '1' ? '发布' : '取消发布';
      (proxy as any).$confirm({
        title: `${statusName}确认`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: `确认${statusName}选中资料吗？`,
        onOk: () => {
          return new Promise((resolve) => {
            updateStatus({
              status,
              materialIds: data.selectedRowKeys
            }).then(() => {
              (proxy as any).$message.success(`资料${statusName}成功`);
              table.value.refresh();
            }).finally(() => resolve(true))
          });
        },
      });
    }

    const options = ref([])
    findByTree({}).then((res: { data: any; }) => {
      options.value = res.data
    })
    const download = (val: any) => {
      window.open(val.path)
    }
    const reset = () => {
      updatedTime.value = []
      data.categoryIds=[]
    };
    return {
      downloadSelectApi,
      updatedTime,
      reset,
      download,
      handleDownload,
      options,
      ...toRefs(data),
      table,
      formSearch,
      popupWindow,
      getDataApi,
      onSelectChange,
      handleAdd,
      handleUpdate,
      handleDelete,
      handleDeleteAll,
      handleUpdateStatus
    };
  },
});
