<template>
  <div>
    <a-modal
      width="600px"
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
      >
        <a-form-item
          label="资料类别："
          name="categoryIds"
        >
          <a-cascader
            v-model:value="formState.categoryIds"
            :field-names="{ label: 'name', value: 'categoryId', children: 'children' }"
            :options="options"
            change-on-select
            placeholder="请选择"
          />
          <!-- <a-select ref="select" v-model:value="formState.categoryId" :options="typeOptions"></a-select> -->
        </a-form-item>
        <a-form-item
          label="资料名称："
          name="title"
        >
          <a-input
            v-model:value="formState.title"
            allowClear
          />
        </a-form-item>
        <a-form-item
          label="上传资料附件："
          name="path"
        >
          <upload
            v-if="visible"
            ref="upload"
            @handleChange="handleChangeUpload"
            :FormData="formState"
          ></upload>
          <span style="margin-left:10px">附件格式为PDF格式或mp4格式，大小不超过500M</span>
        </a-form-item>
        <a-form-item
          label="封面图片："
          name="img"
        >
          <uploadPictures
            v-if="visible"
            ref="uploadPictures"
            :FormData="formState"
            @handleChange="handleChangeUploadPictures"
          >
          </uploadPictures>
          <span style="margin-left:10px">封面格式为JPG、PNG格式，尺寸为4:3，大小不超过1M</span>
        </a-form-item>
        <a-form-item
          label="学习时长："
          name="duration"
        >
          <a-time-picker
            v-model:value="formState.duration"
            value-format="HH:mm:ss"
            :default-value="'00:01:00'"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import upload from "@/views/components/upload";
import uploadPictures from "@/views/components/uploadPictures";
import { defineComponent } from "vue";
import { find } from '@/api/api'
import { add, update, findByTree } from '@/api/meaterial/documentation'

const basicForm = {
  categoryIds: [],
  title: '',
  duration: '00:01:00',
  type: '',
  path: '',
  img: '',
  name: ''
}

export default defineComponent({
  name: "Form",
  components: {
    upload,
    uploadPictures
  },
  props: {
    edit: Boolean,
    typeOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      options: [
      ],
      //文件地址
      upload: '',
      //封面图片
      uploadPictures: '',
      formState: {
        ...basicForm
      },
      confirmLoading: false,
      visible: false,
      rules: {
        categoryIds: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
        path: [{ required: true, message: '不能为空', trigger: 'blur' }],
        img: [{ required: true, message: '不能为空', trigger: 'blur' }],
        duration: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
      },
    };
  },
  created() {
  },
  computed: {
    // modalTitle() {
    //   const typeName = this.formState.type === '1' ? '文档' : '视频'
    //   return !this.edit ? `新增${typeName}资料` : `编辑${typeName}资料`
    // }
    modalTitle() {
      return !this.edit ? `新增资料` : `编辑资料`
    }
  },
  methods: {
    //上传文件回调
    handleChangeUpload(info) {
      if(info.response.data?.path){
        this.formState.path = info.response.data.path
        this.formState.name = info.response.data.name
        if(info.response.data?.fileType==='video'){
          this.formState.type=2
        }else{
          this.formState.type=1
        }
      }else{
        this.$message.error('上传服务器失败，请重新上传')
      }
    },
    //上传封面图片回调
    handleChangeUploadPictures(info) {
      if(info.response.data?.path){
        this.formState.img = info.response?.data.path
      }else{
        this.$message.error('上传服务器失败，请重新上传')
      }
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.visible = false;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        const fun = this.edit ? update : add
        fun({
          ...this.formState,
          categoryId: this.formState.categoryIds[this.formState.categoryIds.length - 1]
        }).then(res => {
          this.$message.success(`${this.edit ? '编辑' : '新增'}资料成功`)
          this.$emit('success')
          this.visible = false
        }).finally(() => {
          this.confirmLoading = false
        })
      });
    },
    showModal(formState = {}) {
      this.formState = {
        ...basicForm,
        ...formState
      }
      findByTree().then(res => {
        this.options = res.data
      })
      this.visible = true;
      this.$nextTick(() => {
        if (this.formState.type == 1) {
          this.$refs.upload.init()
        }
        if (this.formState.type == 2) {
          this.$refs.upload.init()
        }
        this.$refs.uploadPictures.init()
      })
    }
  },
});
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    