import { defineComponent, reactive, PropType, ref, onBeforeMount, watch, watchEffect, onBeforeUnmount, renderSlot } from 'vue'
import { RowProps, ColProps, FormItemProps } from 'ant-design-vue'
import formItem from './form-item'
import { ComConfig } from './form-item'
import _ from 'lodash'
import './form.less'


export interface FormConfig {
    colProps?: ColProps,
    formItemProps?: FormItemProps,
    comConfig: ComConfig
}

export interface FormBtn {
    colProps?: ColProps,
    formItemProps?: FormItemProps,
}

interface FormState {
    formState: any,
    configArr: FormConfig[]
}

export default defineComponent({
    components: { formItem },
    props: {
        formConfig: Object as PropType<FormConfig[]>,
        formBtn: {
            type: Object as PropType<FormBtn>,
            default: () => {
                return {
                    colProps: { span: 8 },
                    formItemProps: {
                        wrapperCol: {
                            span: 24
                        }
                    }
                }
            }
        }
    },
    setup(props: any, { emit, expose, slots }) {
        const state: FormState = reactive({
            formState: {},
            configArr: []
        })
        const formRef = ref();
        const init = () => {
            state.configArr = _.cloneDeep(props.formConfig)
            const comArr = props.formConfig.map((item: FormConfig) => item.comConfig)
            const apiArr = comArr.filter((item: ComConfig) => item.tag === 'select' && item.getDataApi)
            Promise.all(apiArr.map((item: ComConfig) => (item as any).getDataApi())).then(resArr => {
                // console.log('res', resArr);
                for (let i = 0; i < resArr.length; i++) {
                    const index = state.configArr.findIndex((data: FormConfig) => data.comConfig.key === apiArr[i].key)
                    const { labelKey = 'label', valueKey = 'value' } = apiArr[i]
                    const options = Array.isArray(resArr[i]) ? resArr[i] : (resArr[i] as any).data
                    state.configArr[index].comConfig.props.options = options.map((data: any) => ({
                        ...data,
                        value: data[valueKey],
                        label: data[labelKey]
                    }))
                }
            })
        }
        const getQuery = () => {
            return state.formState
        }

        expose({
            getQuery
        })

        const handleInput = (key: string, value: InputEvent) => {
            state.formState[key] = value
            emit('input', key, value)
        }
        const handleReset = () => {
            state.formState = {}
            emit('reset', {})
        }
        const handleSearch = () => {
            emit('search', state.formState)
        }
        watch(props.formConfig, (value) => {
            init()
        }, {
            immediate: true
        })
        const renderFormItem = (comConfig: ComConfig) => {
            const { slots: formItemSlots, key } = comConfig
            if (slots[key]) {
                return renderSlot(slots, key, {
                    ...comConfig
                })
            }
            if (formItemSlots) {
                return (formItemSlots as any)(comConfig)
            }
            return <formItem {...{ comConfig: comConfig, value: state.formState[comConfig.key], 'onUpdate:value': handleInput.bind(null, comConfig.key) }} />
        }
        onBeforeMount(() => {
            // init()
        })
        onBeforeUnmount(() => {
            // stopWatch()
        })
        return () => (
            <a-form
                ref={formRef}
                model={state.formState}
                labelAlign="left"
                class="form-search"
            >
                <a-row>
                    {
                        state.configArr.map((item: FormConfig) => (
                            <a-col {...{ ...(item.colProps || { span: 8 }) }}>
                                <a-form-item {...{
                                    ...{
                                        labelCol: {
                                            span: 5,
                                        },
                                        wrapperCol: {
                                            span: 14
                                        },
                                        ...(item.formItemProps || {})
                                    }
                                }}>
                                    {renderFormItem(item.comConfig)}
                                </a-form-item>
                            </a-col>
                        ))
                    }
                    <a-col {...{ ...props.formBtn.colProps }}>
                        <a-form-item {...{ ...props.formBtn.formItemProps }}>
                            <div class="form-search-btn">
                                <a-button class="ant-btn-reset" type="default" onClick={handleReset} >重置</a-button>
                                <a-button class="ant-btn-search ant-btn-theme" type="default" onClick={handleSearch}>查询</a-button>
                            </div>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        )
    }
})