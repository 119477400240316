// blob导出
export function blob (res: any) {  //res是后台返回的数据
  const a = document.createElement('a')
  const URL = window.URL || window.webkitURL
  const href = URL.createObjectURL(res)
  a.href = href
  a.download = '导出表格.xls'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(href)
}
